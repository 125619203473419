input {
    padding: 8px;
    border: 1px solid grey;
    border-radius: 8px;
    outline: none;
}

input:focus {
    border-color: #23cf92;
}

Button {
    color: white !important;
}

.map-icon {
    height: 20px;
    width: 20px;
    border-radius: 10px;
}

.map-icon:hover {
    height: 26px;
    width: 26px;
    border-radius: 13px;
}

.fade-enter {
    opacity: 0.007
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-leave.fade-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;

}

.fade-leave {
    opacity: 1
}